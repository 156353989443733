<template lang="pug">
.vc-product-subscription-button.button.is-odd(@click="buttonInfo.action")
  | {{ buttonInfo.text }}
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore, useToast } from 'skid-composables'
import Form from 'odd-form_object'

const props = defineProps({
  variantId: {
    type: [String, Number],
    required: true
  },

  quantity: {
    type: [String, Number],
    required: true
  }
})

const store = useStore()
const currentUser = computed(() => store.getters['currentUser'])
const isUserLoggedIn = computed(() => {
  return currentUser.value.type === 'User'
})

const currentSubscriptionInvoiceId = ref(null)
const currentSubscriptionInvoice = computed(() =>
  store.getters['oddPayInvoices/find'](currentSubscriptionInvoiceId.value)
)

const visitLoginPage = () => {
  Turbolinks.visit(`/user/login`)
}

const assignVariantToInvoice = async () => {
  await store
    .dispatch('users/fetchCurrentSubscriptionInvoice')
    .then((response) => {
      currentSubscriptionInvoiceId.value = response.data.data.id
    })

  const form = new Form(currentSubscriptionInvoice.value)

  form.variant_id = props.variantId
  form.quantity = props.quantity

  store
    .dispatch('users/assignVariantToOddPayInvoice', form.sync())
    .then(() => {
      Turbolinks.visit(`/odd_pay/users/subscription`)
    })
    .catch((error) => {
      const messages = error.response.data.validation_errors?.base || []

      messages.map((message) => {
        useToast({
          message: message,
          type: 'is-info'
        })
      })
    })
}

const buttonInfo = computed(() => {
  return {
    text: isUserLoggedIn.value ? '立即訂閱' : '請先登入會員',
    action: isUserLoggedIn.value ? assignVariantToInvoice : visitLoginPage
  }
})
</script>
