import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'custom_page_sections',
  attributes: [
    'id',
    'page_id',
    'category',
    'header_mode',
    'header_data',
    'content_data',
    'position',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['category', 'header_mode', 'header_data', 'content_data']
}

const CATEGORY_DATA_MAP = [
  'banner',
  'modal_link',
  'promoter_share',
  'sales_event',
  'product_category',
  'promoter_campaign',
  'match_product',
  'partner_plan',
  'customer_service'
]

export default class CustomPageSection extends ResourceModelBase {
  constructor(attributes = {}) {
    super(
      OPTIONS,
      Object.assign(
        {
          header_data: {},
          content_data: {}
        },
        attributes
      )
    )
  }

  static categories() {
    return CATEGORY_DATA_MAP
  }
}
