<template lang="pug">
template-section.vc-custom-page-section-promoter-share-section(
  :custom-page-section="customPageSection"
)
  template(#body)
    card-list(
      v-if="data.isDataInited"
      :promoter-shares="promoterShares"
    )
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, watch } from 'vue'
import { useStore } from 'skid-composables'
import TemplateSection from './template-section.vue'
import cardList from './promoter_share_section/card-list.vue'

// props
const props = defineProps<{
  customPageSection: { type: Object; requred: true }
}>()

const store = useStore()
const data = reactive({
  isDataInited: false
})

/**
 * @returns {computedRef<Array<String>>}
 */
const promoterShareIds = computed<Array<String>>(() => {
  return props.customPageSection.content_data.ids || []
})
/**
 * @returns {computedRef<Boolean>}
 */
const isShowContainer = computed<Boolean>(() => {
  return promoterShareIds.value.length > 0
})

const promoterShares = computed<Array<Object>>(() => {
  return promoterShareIds.value.map((id) => {
    return store.getters['promoterShares/find'](id)
  })
})

const promoterEvent = computed(() => {
  return store.getters['promoterEvents/kolcenter']
})

async function getPromoterEvent() {
  return store.dispatch('promoterEvents/getKolcenter')
}

async function fetchPromoterShares() {
  if (promoterShareIds.value.length === 0) return

  await store.dispatch('promoterEvents/fetchShares', {
    model: promoterEvent.value,
    options: {
      search: {
        id_in: promoterShareIds.value
      }
    }
  })
}

watch(
  computed(() => props.customPageSection.content_data),
  () => {
    fetchPromoterShares()
  }
)

onMounted(async () => {
  await getPromoterEvent()

  await fetchPromoterShares()

  data.isDataInited = true
})
</script>
