<template lang="pug">
.vc-sales-event-k-card
  .inner
    .cover-wrapper
      .cover(:style="`background-image: url('${salesEvent.cover.url}')`")
        .overlay
        .sales-info
          h2.name {{ salesEvent.name }}
          .countdown-wrapper(:class="{ 'is-live': salesEvent.isLive() }")
            .counter-inner
              countdown(
                :time="countdownTime"
                :interval="1000"
              )
                template(slot-scope="props")
                  span.counter
                    span.days
                      span.unit(
                        v-for="(day, index) in confirmedDays(props.days)"
                        :key="index"
                      ) {{ day }}
                    span.hours
                      span.unit(
                        v-for="(hour, index) in splitNumber(props.hours)"
                        :key="index"
                      ) {{ hour }}
                    span.minutes
                      span.unit(
                        v-for="(minute, index) in splitNumber(props.minutes)"
                        :key="index"
                      ) {{ minute }}
                    span.seconds
                      span.unit(
                        v-for="(second, index) in splitNumber(props.seconds)"
                        :key="index"
                      ) {{ second }}
    .info-wrapper
      .products-wrapper
        product-list(:sales-event="salesEvent")
      .options
        a.button.is-default.is-outlined.is-fullwidth(
          :href="`/sales-events/${salesEvent.id}`"
        )
          span {{ copyLocaleText('view_the_best_price') }}
</template>

<script>
import productList from './product-list.vue'
import salesEventMixin from '@applicationComponents/mixins/sales_event_mixin.js'

export default {
  components: {
    productList
  },

  mixins: [salesEventMixin],

  props: {
    salesEvent: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    confirmedDays(days) {
      if (parseInt(days) >= 99) return String(99).split('')

      return String(days).split('')
    },

    splitNumber(number) {
      return String(number).padStart(2, '0').split('')
    }
  }
}
</script>

<style lang="sass">
@use 'sass:math'
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'

.vc-sales-event-k-card
  $count-unit-width-s: 18px
  $count-unit-width-l: 25px

  > .inner
    transition: all .3s
    height: 100%
    border-radius: 20px
    overflow: hidden
    box-shadow: 0px 0px 1rem rgba(color.$black, 0.1)
    display: grid
    grid-template-rows: auto 1fr
    &:hover
      box-shadow: 0 0 1rem rgba(color.$black, .25)
      transform: translateY(-2px)

  .cover
    background-size: cover
    position: relative
    aspect-ratio: 32 / 21
    > *
      position: absolute
    > .overlay
      background-color: rgba(color.$black, 0.5)
      backdrop-filter: blur(0)
      inset: 0
      position: absolute
      transition: all .3s
    .sales-info
      position: absolute
      right: 0
      bottom: 0
      left: 0
      font-size: font.$size-6
      font-weight: font.$bold
      text-align: center
      color: color.$white
      +utils.from(size.$ipad-pro-105)
        font-size: font.$small
      +utils.from(size.$macbook-13inch)
        font-size: font.$size-6
    .counter,
    .days,
    .hours,
    .minutes,
    .seconds
      display: grid
      grid-auto-flow: column
      grid-gap: .5em
      justify-content: center
    .days:after
      content: 'Day'
      display: flex
      align-self: center
      font-size: font.$small
    .hours:after,
    .minutes:after
      content: ':'
      display: inline-block

    .unit
      width: $count-unit-width-l
      height: $count-unit-width-l
      border: 1px solid color.$white
      border-radius: 5px
      +utils.from(size.$ipad-pro-105)
        width: $count-unit-width-s
        height: $count-unit-width-s
      +utils.from(size.$macbook-13inch)
        width: $count-unit-width-l
        height: $count-unit-width-l

  .sales-info
    > .name
      line-height: 2em
    > .countdown-wrapper
      padding: 1em 0

  > .inner > .info-wrapper
    display: flex
    justify-content: space-between
    flex-direction: column
    padding: .5rem
    gap: .5rem

  // .products-wrapper

  // .options
  //   padding: .5rem
  //   .button
</style>
