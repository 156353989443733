import ProductCollectService from '../../../../shared/services/product_collect_service'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  // components: {},

  // props: {},

  data() {
    return {
      isCollectProductProcessing: false
    }
  },

  computed: {
    /**
     * should return target collectable product ID from backend.
     * @returns {string}
     */
    collectableProductId() {
      throw new Error(
        'please implement `collectableProductId` computed property first.'
      )
    },

    /**
     * @returns {Product}
     */
    collectableProduct() {
      return this.$store.getters['products/find'](this.collectableProductId)
    },

    /**
     * check if collectable product is already existed in user collection.
     * @returns {boolean}
     */
    isCollected() {
      return this.currentCollections.includes(this.collectableProductId)
    },

    /**
     * product IDs that in user collection.
     * @returns {string[]}
     */
    currentCollections() {
      return this.$store.getters['userCollections/currentCollections']
    },

    /**
     * user latest collection update time in UNIX time format.
     * @returns {?number}
     */
    collectionsLatestUpdatedAt() {
      return this.$store.getters['userCollections/latestUpdatedAt']
    },

    /**
     * @returns {{"fa-heart-o": boolean, "fa-heart": boolean}}
     */
    collectButtonIcon() {
      return {
        'fa-heart': this.isCollected,
        'fa-heart-o': !this.isCollected
      }
    },

    /**
     * @returns {{short: string, long: string}}
     */
    collectButtonText() {
      if (this.isCollected) {
        return {
          short: this.isCollectProductProcessing
            ? this.actionLocaleText('processing')
            : this.actionLocaleText('uncollect'),
          long: this.isCollectProductProcessing
            ? this.actionLocaleText('processing')
            : this.actionLocaleText('uncollect')
        }
      } else {
        return {
          short: this.isCollectProductProcessing
            ? this.actionLocaleText('processing')
            : this.actionLocaleText('collect'),
          long: this.isCollectProductProcessing
            ? this.actionLocaleText('processing')
            : this.actionLocaleText('collect_product')
        }
      }
    }
  },

  methods: {
    /**
     * @returns {Promise<void>}
     */
    async collectButtonClickedHandler() {
      this.isCollectProductProcessing = true
      try {
        // if target collectable product not fetched yet, wait for fetch first.
        if (this.collectableProduct.isNewRecord())
          await this.$store.dispatch('products/find', this.collectableProductId)

        if (this.isCollected) {
          this.removeProductFromCollection()
        } else {
          this.addProductToCollection()
        }
      } finally {
        this.isCollectProductProcessing = false
      }
    },

    /**
     * @returns {Promise<void>}
     */
    async addProductToCollection() {
      const service = new ProductCollectService(
        this.currentUser.type,
        this.currentCollections,
        this.$vlf,
        this.$store
      )

      await service.collect(this.collectableProduct)
      await this.$vlf.setItem(
        ProductCollectService.collectionLatestUpdatedAtKey(),
        this.collectionsLatestUpdatedAt
      )
      await this.$store.dispatch('pixelOperation', [
        [
          'track',
          'AddToWishlist',
          {
            content_ids: this.collectableProduct.sku,
            content_name: this.collectableProduct.name,
            content_type: 'Product',
            currency: 'TWD',
            value: this.toMoney(this.collectableProduct.consumer_price, {
              isExchange: false
            }).amount
          }
        ]
      ])
      await this.$store.dispatch('ga4Operation', [
        generateGA4Events('add_to_wishlist', {
          item: this.collectableProduct
        })
      ])
      await this.$store.dispatch('addFlashMessage', [
        'success',
        this.messageLocaleText('product_collected_successfully')
      ])
    },

    /**
     * @returns {Promise<void>}
     */
    async removeProductFromCollection() {
      const service = new ProductCollectService(
        this.currentUser.type,
        this.currentCollections,
        this.$vlf,
        this.$store
      )

      await service.uncollect(this.collectableProduct)
      await this.$vlf.setItem(
        ProductCollectService.collectionLatestUpdatedAtKey(),
        this.collectionsLatestUpdatedAt
      )
      await this.$store.dispatch('addFlashMessage', [
        'success',
        this.messageLocaleText('product_removed_from_collection_successfully')
      ])
    }
  }
}
