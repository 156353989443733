<template lang="pug">
k-card-container.vc-kolcenter-promoter-campaign-official-index-container-resources-list(
  :data="resources"
  style="--grid-template-columns: repeat(auto-fill, minmax(320px, 1fr))"
)
  template(#card="props")
    template(v-if="props.row.has_periods")
      card(
        v-for="(campaignPeriod, index) in campaignPeriodsFor(props.row)"
        :key="campaignPeriod.id"
        :promoter-share="promoterShare"
        :resource="props.row"
        :sel-promoter-campaign-period="campaignPeriod"
      )
    template(v-else)
      card(
        :promoter-share="promoterShare"
        :resource="props.row"
      )
</template>

<script setup>
// import { computed } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import kCardContainer from '@sharedComponents/common/k-card-container.vue'
import Card from '@sharedComponents/promoter_campaign/common/official-campaign-card.vue'

const store = useStore()
const props = defineProps({
  promoterShare: { type: Object, required: true },
  resources: { type: Array, required: true },
  total: { type: Number },
  currentPage: { type: Number },
  pageSize: { type: Number }
})
const emit = defineEmits(['on-sort', 'page-change'])

const pageChangeHandler = (page) => {
  emit('page-change', page)
}

function campaignPeriodsFor(promoterCampaign) {
  return store.getters['promoterCampaignPeriods/filterBy']({
    campaign_id: parseInt(promoterCampaign.id)
  })
}
</script>
