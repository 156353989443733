<template lang="pug">
.vc-product-purchase-button.option-button
  .button(
    @click="clickHandler"
    :class="soldOutClass"
  )
    .icon
      i.fa.fa-shopping-cart
    template(v-if="isPurchasable")
      span.text {{ actionLocaleText('select_goods') }}
    template(v-else)
      span.text {{ unPurchaseableText }}
</template>

<script>
import ActionConfirmService from '@services/action_confirm_service.js'
import productPropsMixin from '../mixins/product_props_mixin.js'
import VariantsSelector from './variants-selector.vue'

export default {
  components: {
    VariantsSelector
  },

  mixins: [productPropsMixin],

  // props: {},

  // data() {
  //   return {}
  // },

  computed: {
    soldOutClass() {
      return {
        'is-sold-out': !this.isPurchasable
      }
    },

    isPurchasable() {
      return this.product.isReleased && this.product.isPurchasable()
    },

    unPurchaseableText() {
      return this.copyLocaleText(
        this.product.isReleased ? 'stock_status.no_stock' : 'coming_soon'
      )
    }
  },

  // created() {},
  // mounted() {},
  methods: {
    clickHandler() {
      if (!this.product.isPurchasable()) return this.showSoldOutMessage()
      if (this.product.is_age_restricted) {
        return new ActionConfirmService({
          title: this.messageLocaleText('we_have_to_confirm_your_age_first'),
          text: this.messageLocaleText('this_is_adult_only_product'),
          confirmButtonText: this.actionLocaleText('i_am_adult'),
          cancelButtonText: this.actionLocaleText('i_am_not_adult')
        }).confirm(this._showVariantsSelector)
      }
      this._showVariantsSelector()
    },

    _showVariantsSelector() {
      this.$buefy.modal.open({
        parent: this,
        width: 380,
        component: VariantsSelector,
        props: {
          product: this.product,
          index: this.index,
          listLocation: this.listLocation
        }
      })
    },

    showSoldOutMessage() {
      this.$store.dispatch('addFlashMessage', [
        'notice',
        this.messageLocaleText('help.sorry_this_product_sold_out_currentlly')
      ])
    }
  }
}
</script>
