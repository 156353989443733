<template lang="pug">
.vc-shared-product-category-common-cover-image
  img.image(:src="coverSrc")
  .overlay
  .name {{ productCategory.name }}
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { useStore, useInstance } from 'skid-composables'

const MOBILE_IMAGE_BREAK_POINT = 415

const props = defineProps({
  productCategory: { type: Object, required: true }
})

const store = useStore()
const vueInstance = useInstance()

const isLoading = computed(() => {
  return store.getters['productCategories/isLoading']
})

const isMobile = computed(() => {
  // workaround for safari can't get window width from vuex at first time loading
  return (
    (store.getters['windowWidth'] || window.innerWidth) <
    MOBILE_IMAGE_BREAK_POINT
  )
})

const coverSrc = computed(() => {
  return isMobile.value
    ? props.productCategory.mobile_cover?.url || this.productCategory.cover?.url
    : props.productCategory.cover?.url
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/size.sass'

.vc-shared-product-category-common-cover-image
  --mobile-cover-ratio: 750 / 272
  --cover-ratio: 272 / 690
  $border-radius: 20px

  width: var(--mobile-cvero-width, 100%)
  aspect-ratio: var(--mobile-cover-ratio)

  position: relative
  border-radius: $border-radius
  background-color: color.$grey
  overflow: hidden
  +utils.from(size.$ipad)
    width: var(--cover-width, 136px)
    aspect-ratio: var(--cover-ratio)
    height: 100%

  > .image
    width: 100%
    height: 100%
    object-fit: cover

  > .overlay
    position: absolute
    background-color: rgba(color.$black, 0.5)
    backdrop-filter: blur(0)
    inset: 0
    transition: all .3s

  > .name
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: font.$size-5
    font-weight: font.$medium
    color: color.$white
    +utils.from(size.$ipad)
      writing-mode: vertical-lr
</style>
