import * as types from '../mutation-types'
import CustomPage from '@models/custom_page'

export const saveSection = (
  { dispatch, commit },
  { model, customPageSection, form }
) => {
  commit(types.API_REQUEST_START, 'saveSection')
  return new Promise((resolve, reject) => {
    model
      .saveSection({ customPageSection, form })
      .then((response) => {
        dispatch('customPages/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'customPageSections/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'saveSection')

        resolve(response)
      })
      .catch((errors) => {
        customPageSection.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: saveSection,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroySection = (
  { dispatch, commit },
  { model, customPageSection }
) => {
  commit(types.API_REQUEST_START, 'destroySection')
  return new Promise((resolve, reject) => {
    model
      .destroySection({ customPageSection })
      .then((response) => {
        dispatch(
          'customPageSections/destroyResourcesFromRelationships',
          customPageSection.id,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'destroySection')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroySection,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const sortSection = (
  { dispatch, commit },
  { model, customPageSection, direction }
) => {
  commit(types.API_REQUEST_START, 'sortSection')
  return new Promise((resolve, reject) => {
    model
      .sortSection({ customPageSection, direction })
      .then((response) => {
        dispatch('customPages/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'customPageSections/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'sortSection')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: sortSection,
            ref: {
              dispatch,
              commit
            },
            params: { model, customPageSection, direction }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
