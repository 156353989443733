export const FETCH_CUSTOM_PAGE_SECTIONS_SUCCESS =
  'FETCH_CUSTOM_PAGE_SECTIONS_SUCCESS'
export const GET_RELATED_CUSTOM_PAGE_SECTIONS_SUCCESS =
  'GET_RELATED_CUSTOM_PAGE_SECTIONS_SUCCESS'
export const GET_CUSTOM_PAGE_SECTION_SUCCESS = 'GET_CUSTOM_PAGE_SECTION_SUCCESS'
export const ADD_CUSTOM_PAGE_SECTION_SUCCESS = 'ADD_CUSTOM_PAGE_SECTION_SUCCESS'
export const UPDATE_CUSTOM_PAGE_SECTION_SUCCESS =
  'UPDATE_CUSTOM_PAGE_SECTION_SUCCESS'
export const DELETE_CUSTOM_PAGE_SECTION_SUCCESS =
  'DELETE_CUSTOM_PAGE_SECTION_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
