import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'videos',
  attributes: [
    'id',
    'created_at',
    'updated_at',
    'title',
    'description',
    'cloudflare_id',
    'is_uploaded',
    'thumbnail_url',
    'stream_url'
  ],
  editableAttributes: ['title', 'description']
}

export default class Video extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  save(formData) {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), formData)
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, formData)
  }
}
