<template lang="pug">
.vc-shared-product-category-common-products-view
  cover-image(:product-category="category")

  .products-wrapper(
    :style="`grid-template-columns: repeat(${products.length <= 4 ? 4 : products.length}, minmax(25%, 1fr))`"
  )
    product-card(
      v-for="(product, index) in products"
      :key="product.id"
      :product="product"
      :index="index"
      :list-location="listLocation"
      @ga-impressed="impressedHandler"
      id-prefix="select"
    )
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { useStore, useInstance } from 'skid-composables'
import ProductCard from '@applicationComponents/product/card.vue'
import debounce from 'lodash.debounce'
import generateGA4Events from '@services/generate_ga4_events'
import CoverImage from './cover-image.vue'

const MOBILE_IMAGE_BREAK_POINT = 415

const props = defineProps({
  category: { type: Object, required: true },

  productCount: {
    type: Number,
    default: () => {
      return 8
    }
  }
})

const store = useStore()
const vueInstance = useInstance()

const data = reactive({
  productIds: []
})

const isAdmin = computed(() => {
  return vueInstance.currentUser.type === 'Admin'
})

const isLoading = computed(() => {
  return store.getters['productCategories/isLoading']
})

const products = computed(() => {
  return data.productIds.map((id) => {
    return store.getters['products/find'](id)
  })
})

const listLocation = computed(() => {
  return `Select Goods - ${props.category?.name}`
})

async function fetchProducts() {
  const response = await store.dispatch('productCategories/selectGoods', {
    model: props.category,
    options: {
      pageSize: props.productCount,
      pageNumber: 1
    }
  })

  data.productIds = response.data.data.map((product) => product.id)

  await store.dispatch('ga4Operation', [
    generateGA4Events('view_item_list', {
      items: products.value,
      item_list_name: listLocation.value
    })
  ])
}

const impressedHandler = debounce(function () {
  if (isAdmin.value) return

  store.dispatch('gaOperation', [
    [
      'send',
      'event',
      'Ecommerce',
      'Impressions',
      listLocation.value,
      {
        nonInteraction: true
      }
    ]
  ])
}, 800)

onMounted(async () => {
  await fetchProducts()
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/size.sass'

.vc-shared-product-category-common-products-view
  margin-bottom: size.$gap
  width: 100%
  display: grid
  gap: size.$gap * 0.5
  +utils.from(size.$ipad)
    grid-template-columns: auto 1fr
    grid-template-rows: unset

  > .products-wrapper
    display: flex
    flex-wrap: wrap
    +utils.from(size.$ipad)
      display: grid
      grid-template-columns: repeat(8, minmax(25%, 1fr))
      overflow-x: scroll
    > .vc-product-card
      +utils.from(size.$ipad)
        width: auto
</style>
