import { computed } from 'vue'
import { useInstance } from 'skid-composables'
import ShoppingCartService from '@services/shopping_cart_service.js'
import _union from 'lodash.union'
import _intersection from 'lodash.intersection'
import _flatten from 'lodash.flatten'

export const useAddOnPresentable = (store) => {
  const fetchAddOnGroups = async (attachedProductIds = []) => {
    return store.dispatch('addOnGroups/all', {
      search: {
        products_id_in: attachedProductIds,
        is_available_eq: true
      }
    })
  }

  const availableGroups = computed(() =>
    store.getters['addOnGroups/all'].filter((group) => group.is_available)
  )

  const availableGroupIds = computed(() =>
    availableGroups.value.map((group) => group.id)
  )

  const availableItems = computed(() =>
    store.getters['addOnItems/all'].filter(
      (item) =>
        item.is_available &&
        availableGroupIds.value.includes(`${item.add_on_group_id}`)
    )
  )

  const availableItemIds = computed(() =>
    availableItems.value.map((item) => item.id)
  )

  const availablePrices = computed(() =>
    store.getters['addOnItemPrices/all'].filter(
      (price) =>
        price.is_available &&
        availableItemIds.value.includes(`${price.add_on_item_id}`)
    )
  )

  const attachedProductIds = computed(() =>
    _union(
      _flatten(
        availableGroups.value.map((group) =>
          group.products.map((product) => product.id)
        )
      )
    )
  )

  const results = computed(() =>
    availablePrices.value.map((price) => {
      return {
        price,
        variant: store.getters['productVariants/find'](price.variant_id),
        item: store.getters['addOnItems/find'](price.add_on_item_id)
      }
    })
  )
  const vueInstance = useInstance()
  const cartService = new ShoppingCartService(vueInstance.$vlf, store)
  const allItems = computed(() =>
    store.getters['orderItems/fromCurrentOrder']()
  )

  const allItemQuantities = computed(() =>
    allItems.value.reduce((map, item) => {
      map[item.variant_id] = (map[item.variant_id] || 0) + item.quantity
      return map
    }, {})
  )

  const addOnItems = computed(() =>
    allItems.value.filter((item) => !!item.add_on_item_price_id)
  )

  const addOnItemQuantities = computed(() =>
    addOnItems.value.reduce((map, item) => {
      map[item.variant_id] = (map[item.variant_id] || 0) + item.quantity
      return map
    }, {})
  )

  const normalItems = computed(() =>
    allItems.value.filter((item) => !item.add_on_item_price_id)
  )

  const normalItemQuantities = computed(() =>
    normalItems.value.reduce((map, item) => {
      map[item.variant_id] = (map[item.variant_id] || 0) + item.quantity
      return map
    }, {})
  )

  const isMainProductPurchased = computed(
    () =>
      _intersection(attachedProductIds.value, cartItemProductIds.value).length >
      0
  )

  const cartItemProductIds = computed(() =>
    normalItems.value.map((item) => item.product.id)
  )

  return {
    fetchAddOnGroups,
    isMainProductPurchased,
    cartService,
    allItemQuantities,
    normalItemQuantities,
    addOnItemQuantities,
    cartItemProductIds,
    results
  }
}

export default useAddOnPresentable
