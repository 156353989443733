<template lang="pug">
template-section.vc-custom-page-section-category-section(
  :custom-page-section="customPageSection"
)
  template(#body)
    category-products-view(
      v-if="data.isDataInited"
      v-for="category in categories"
      :category="category"
      :key="category.id"
    )
</template>

<script setup lang="ts">
import { reactive, computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import TemplateSection from './template-section.vue'
import categoryProductsView from '@sharedComponents/product_category/common/products-view.vue'

// props
const props = defineProps<{
  customPageSection: { type: Object; requred: true }
}>()

const store = useStore()

const data = reactive({
  isDataInited: false
})

/**
 * @returns {computedRef<Array<String>>}
 */
const categoryIds = computed<Array<String>>(() => {
  return props.customPageSection.content_data.ids || []
})

/**
 * @returns {computedRef<Array>}
 */
const categories = computed<Array<Object>>(() => {
  return categoryIds.value.map((id) =>
    store.getters['productCategories/find'](id)
  )
})

// methods
/**
 * @returns {promoise<void>}
 */
const fetchCategories = () => {
  if (categoryIds.value.length === 0) return

  return store.dispatch('productCategories/all', {
    sort: 'position',
    search: {
      id_in: categoryIds.value
    }
  })
}

// mounted
onMounted(async () => {
  await fetchCategories()

  data.isDataInited = true
})
</script>
