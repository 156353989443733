<template lang="pug">
template-section.vc-custom-page-promoter-campaign-section(
  :custom-page-section="customPageSection"
)
  template(#body)
    div(
      v-if="data.isDataInited"
      :is="component"
      :promoter-share="promoterShare"
    )
</template>

<script setup lang="ts">
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'skid-composables'
import TemplateSection from './template-section.vue'
import EventCampaignIndexContainer from '@sharedComponents/promoter_campaign/common/event-campaign-index-container.vue'
import OfficialCampaignIndexContainer from '@sharedComponents/promoter_campaign/common/official-campaign-index-container.vue'
import CurrentUserAssignService from '@shared/services/current_user_assign_service'
import axios from 'axios'

const COMPONENT_MAP = {
  open_event: EventCampaignIndexContainer,
  open_main: OfficialCampaignIndexContainer
}

// props
const props = defineProps<{
  customPageSection: { type: Object; requred: true }
}>()

const store = useStore()

const data = reactive({
  isDataInited: false
})

const isKolLogin = computed(() => {
  const kolUser = CurrentUserAssignService.call('promoter_member')

  return kolUser.type === 'PromoterMember'
})

const dataSource = computed(() => {
  return props.customPageSection.content_data.data_source
})

const promoterShare = computed(() => {
  return store.getters['promoterShares/currentKolShare']
})

const component = computed(() => {
  return COMPONENT_MAP[dataSource.value]
})

function getPromoterEvent() {
  return store.dispatch('promoterEvents/getKolcenter')
}

onMounted(async () => {
  if (
    isKolLogin.value &&
    axios.defaults.headers.common['Application-Scope'] !== 'admin'
  ) {
    axios.defaults.headers.common['Application-Scope'] = 'promoter_member'

    await store.dispatch('promoterMembers/getCurrentKolShare')
  } else {
    await getPromoterEvent()
  }

  data.isDataInited = true
})
</script>
