import moment from 'moment'

/**
 * 用來組合「使用者與會員等級」的 Service object
 *
 * @export
 * @class MemberLevelService
 */
export default class MemberLevelService {
  /**
   * constructor
   *
   * @param {Object} vm 從 vue instance 傳入 _vm
   * @param {Object} user Vuex 的 user entity
   * @param {Array} memberLevels Vuex 的 memberLevel entities
   */
  constructor(vm, user, memberLevels) {
    this.vm = vm
    this.user = user
    this.memberLevels = memberLevels
    this.ordersWithCreatedValue = []
  }

  /**
   * 取得使用者的 使用者的有效價值訂單列表
   *
   * @returns
   */
  async initialize() {
    this.ordersWithCreatedValue = await this._fetchOrderList()
  }

  get orderListCacheKey() {
    return `users/${this.user.id}/orders-with-created-value/${this.user.updated_at}`
  }

  get todayUnix() {
    return moment().startOf('day').format('X')
  }

  get currentLevel() {
    return (
      this.memberLevels.find(
        (level) => this.user.member_level_id == level.id
      ) || {}
    )
  }

  isFirstHigherLevel(level) {
    let firstHigherLevelId = this.memberLevels
      .map((level) => level.id)
      .sort()
      .filter((id) => id > (this.currentLevel.id || 0))[0]

    return level.id === firstHigherLevelId
  }

  isCurrentLevel(level) {
    return level.id === this.currentLevel.id
  }

  /**
   * 由高等至低等組合出包含`使用者資訊`的會員等級列表
   *
   * @returns {Array}
   */
  get levelsWithInformation() {
    return this.memberLevels.map((level) => {
      let isCurrentLevel = this.isCurrentLevel(level)
      let isShow = this.currentLevel.id
        ? isCurrentLevel
        : this.isFirstHigherLevel(level)
      let title = isCurrentLevel ? level.title + '（目前等級）' : level.title

      let information = {
        title,
        isShow,
        meritsInfo: this.getMeritsInfo(level.merits_list),
        grantCriteriaInfo: this.getCriteriaInfo(level, 'grant_criteria'),
        renewCriteriaInfo: this.getCriteriaInfo(level, 'renew_criteria')
      }
      return { ...level, ...information }
    })
  }

  /**
   * 取得個別條件與等級相關的資訊
   *
   * @param {Object} level
   * @param {String} criteriaMode 標準類型：grant_criteria 或 renew_criteria
   * @returns {Array}
   */
  getCriteriaInfo(level, criteriaMode) {
    let criteria = level.preferences[criteriaMode]

    if (!criteria) return []

    return criteria.map((criterion) => {
      let config = criterion.config
      switch (criterion.key) {
        case 'user_value_within_time_range':
          let endedAt

          switch (criteriaMode) {
            case 'grant_criteria':
              endedAt = this.isFirstHigherLevel(level) ? this.todayUnix : null
              break
            case 'renew_criteria':
              let currentLevelEndAt = this.user.level_info.ended_at
              endedAt =
                this.isCurrentLevel(level) && currentLevelEndAt
                  ? currentLevelEndAt
                  : null
              break
          }

          return this.getValueWithinTimeRangeInfo(config, endedAt)
        default:
          return {}
      }
    })
  }

  /**
   * 包含條件敘述與使用者資訊的物件
   *
   * @param {Object} level
   * @param {String} criteriaMode 標準類型：grant_criteria 或 renew_criteria
   * @returns {Object} {description, progressData}
   */
  getValueWithinTimeRangeInfo(config, endedAt) {
    let periodInMonths = config.period_in_months
    let startedAt = endedAt
      ? moment(endedAt, 'X').subtract(periodInMonths, 'months').format('X')
      : null
    let targetValueCents = config.min_created_value * 100

    let description = `${periodInMonths} 個月內購買金額累計 ${this.vm
      .toMoney(targetValueCents)
      .format()} 元`
    let progressData

    let todayIsInRange = this.isInDayRange(this.todayUnix, startedAt, endedAt)

    if (todayIsInRange) {
      let valueInCents = this.createdValueBetween(startedAt, endedAt)
      let isValueReached = valueInCents >= targetValueCents

      progressData = {
        value: valueInCents,
        max: targetValueCents,
        message: isValueReached
          ? '已達成'
          : `尚未達成，還需 ${this.vm
              .toMoney(targetValueCents - valueInCents)
              .format()} 元`
      }
    }

    return {
      description,
      progressData
    }
  }

  /**
   * 取得有效時間內的訂單金額總和
   *
   * @param {Integer} startedAt 開始時間 unix timestamp
   * @param {Integer} endedAt 結束時間 unix timestamp
   * @returns {Integer} 有效金額累計
   */
  createdValueBetween(startedAt, endedAt) {
    return this.ordersWithCreatedValue.reduce((acc, order) => {
      if (this.isInDayRange(order.attributes.effected_at, startedAt, endedAt)) {
        return acc + order.attributes.real_paid_amount_cents
      } else {
        return acc
      }
    }, 0)
  }

  /**
   * 以天為單位，條件時間是否在指定區間內
   * 任何一個參數是 null 或 undefined 則回傳 false
   *
   * @param {Integer} time 條件時間 unix timestamp
   * @param {Integer} startedAt 開始時間 unix timestamp
   * @param {Integer} endedAt 結束時間 unix timestamp
   * @returns {Boolean}
   */
  isInDayRange(time, startedAt, endedAt) {
    if (!time || !startedAt || !endedAt) return false

    let targetDay = moment(time, 'X').startOf('day')
    let startedDay = moment(startedAt, 'X').startOf('day')
    let endedDay = moment(endedAt, 'X').startOf('day')

    return (
      targetDay.isSameOrAfter(startedDay) && targetDay.isSameOrBefore(endedDay)
    )
  }

  /**
   * 組合優惠訊息
   *
   * @param {Array} merits
   * @returns {Array} [{description}]
   */
  getMeritsInfo(merits) {
    if (!merits) return []

    return merits.map((merit) => {
      let config = merit.config
      let description

      switch (merit.key) {
        case 'order_item_adjustment':
          if (config.calculator_type === 'flat_percent') {
            description = `商品折扣 ${config.calculator_config.percent}%`
          }
          break
      }
      return {
        description
      }
    })
  }

  // ======================================================================== //

  _fetchOrderList() {
    return this.vm.$vlf.getItem(this.orderListCacheKey).then((list) => {
      return list || this._getOrdersWithCreatedValues()
    })
  }

  _getOrdersWithCreatedValues() {
    return this.vm.$store
      .dispatch('users/fetchOrdersWithCreatedValue', { model: this.user })
      .then((response) => {
        let list = response.data.data

        this.vm.$vlf.setItem(this.orderListCacheKey, list)

        return list
      })
  }
}
