import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'info_links',
  attributes: [
    'id',
    'created_at',
    'cover',
    'title',
    'sub_title',
    'url',
    'use_type',
    'position',
    'sponsor_type',
    'sponsor_id',
    'sponsor_slogan',
    'sponsor'
  ],
  editableAttributes: [
    'cover',
    'title',
    'sub_title',
    'url',
    'use_type',
    'sponsor_type',
    'sponsor_id',
    'sponsor_slogan'
  ]
}

export default class InfoLink extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static pageSlots() {
    return axios.get(`${new this().apiBasePath()}/page_slots`)
  }

  save(formData) {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), formData)
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, formData)
  }

  // extra methods or helpers here...
  displaySponsorName() {
    if (!this.sponsor) return ''

    return this.sponsor.name
  }
}
