<template lang="pug">
.vc-product-collect-button.option-button
  .button.is-default(
    @click="collectButtonClickedHandler"
    :class="{ 'is-collected': isCollected }"
  )
    .icon
      i.fa(:class="collectButtonIcon")
    span.text.short {{ collectButtonText.short }}
    span.text.long {{ collectButtonText.long }}
</template>

<script>
import productCollectMixin from '../mixins/product_collect_mixin'

export default {
  // components: {},
  mixins: [productCollectMixin],

  props: {
    product: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    collectableProductId() {
      return this.product.id
    }
  }
  // created() {},
  // mounted() {},
  //methods: {},
}
</script>
