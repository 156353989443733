<template lang="pug">
template-section.vc-custom-page-section-sales-event-section(
  :custom-page-section="customPageSection"
  title-mode="mode2"
)
  template(#body)
    card-list(:sales-event-ids="salesEventIds")
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import TemplateSection from './template-section.vue'
import CardList from './sales_event_section/card-list.vue'

// props
const props = defineProps<{
  customPageSection: { type: Object; requred: true }
}>()

const store = useStore()

/**
 * @returns {computedRef<Array<String>>}
 */
const salesEventIds = computed<Array<String>>(() => {
  return props.customPageSection.content_data.ids
})
/**
 * @returns {computedRef<Array<Object>>}
 */
const salesEvents = computed<Array<Object>>(() => {
  return store.getters['salesEvents/all'].filter((salesEvent) => {
    return salesEventIds.value.includes(salesEvent.id)
  })
})
/**
 * @returns {computedRef<Boolean>}
 */
const isShowContainer = computed<Boolean>(() => {
  return salesEvents.value.length > 0
})
</script>
