<template lang="pug">
template-section.vc-custom-page-section-banner-section(
  :class="config.captionMode"
  :custom-page-section="customPageSection"
  :full-screen="fitContainer === 'full_screen'"
)
  template(#body)
    banner-slide-show(
      v-if="useType"
      :useType="useType"
      :options="config"
    )
</template>

<script setup lang="ts">
import { computed } from 'vue'
// import SlideshowContainer from '@applicationComponents/common/slideshow-container.vue'
import TemplateSection from './template-section.vue'
import BannerSlideShow from '@sharedComponents/banner/common/slide-show.vue'

interface CustomPageSection {
  content_data: {
    use_type: string
    container_width: 'in_container' | 'full_screen'
    border_radius: string
    has_overlay: Boolean
    is_below_comp_move_up: Boolean
    bullet_style: 'dot' | 'line'
    caption_mode: 'align_center' | 'align_left'
    caption1: string
    caption2: string
    caption3: string
    caption4: string
    caption5: string
  }
}

// props
const props = defineProps<{
  customPageSection: CustomPageSection
}>()

// computed
/**
 * @returns {computedRef<String>}
 */
const useType = computed(() => {
  return props.customPageSection.content_data.use_type || ''
})

const fitContainer = computed(() => {
  return props.customPageSection.content_data.fit_container
})

const config = computed(() => {
  const contentData = props.customPageSection.content_data

  return {
    useType: contentData.use_type,
    borderRadius: contentData.border_radius,
    hasOverlay: contentData.has_overlay,
    isBelowCompMoveUp: contentData.is_below_comp_move_up,
    bulletStyle: contentData.bullet_style,
    captionMode: contentData.caption_mode,
    caption1: contentData.caption1,
    caption2: contentData.caption2,
    caption3: contentData.caption3,
    caption4: contentData.caption4,
    caption5: contentData.caption5
  }
})
</script>

<style lang="sass">
.vc-custom-page-section-banner-section
  position: relative
  margin-bottom: 2rem
</style>
