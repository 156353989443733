import CustomPageSection from '../../resource_models/custom_page_section'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new CustomPageSection(state.entities[id]))
}

export const filterBy = (state) => (attributes) => {
  return Object.keys(state.entities)
    .map((key) => {
      return state.entities[key]
    })
    .filter((record) => {
      return Object.keys(attributes).every((key) => {
        return record[key] === attributes[key]
      })
    })
    .map((record) => {
      return new CustomPageSection(record)
    })
}

export const find = (state) => (id) => {
  return new CustomPageSection(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
