<template lang="pug">
modal-link-pop-modal.vc-custom-page-section-modal-link-section(
  :active.sync="data.isActive"
  @update:active="activeChange"
  :use-type="useType"
)
</template>

<script setup>
import { reactive, computed, onMounted, ref, watch } from 'vue'
import { useStore, useInstance } from 'skid-composables'
import ModalLinkPopModal from '@sharedComponents/modal_link/common/pop-modal.vue'

const props = defineProps({
  active: { type: Boolean, default: () => true },
  customPageSection: { type: Object, requred: true }
})
const emit = defineEmits(['update:active'])

const store = useStore()
const vueInstance = useInstance()

const data = reactive({
  isActive: props.active
})

const useType = computed(() => {
  return props.customPageSection.content_data.use_type
})

const activeChange = (value) => {
  emit('update:active', value)
}

watch(
  () => props.active,
  () => {
    data.isActive = props.active
  }
)

onMounted(() => {})
</script>
