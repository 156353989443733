<template lang="pug">
.vc-shared-custom-page-section-common-template-section(:class="containerClass")
  .page-section-header.odd-container(v-if="hasTitle")
    .section-title-wrapper
      .section-title
        span.title-1 {{ title }}
        span.title-2(v-if="titleEn") {{ titleEn }}
      .section-subtitle
        span {{ subtitle }}
  .page-section-body(:class="{ 'odd-container': !fullScreen }")
    slot(name="body")
</template>

<script setup lang="ts">
import { computed } from 'vue'

const TITLE_MODE_MAP = {
  title_left_1: '-is-align-left-1',
  title_left_2: '-is-align-left-2',
  title_center_1: '-is-align-center-1'
}

const props = defineProps({
  customPageSection: { type: Object, required: true },
  fullScreen: { type: Boolean }
})

const headerMode = computed(() => {
  return props.customPageSection.header_mode
})

const title = computed(() => {
  return props.customPageSection.header_data.title
})

const titleEn = computed(() => {
  return props.customPageSection.header_data.title_en
})

const subtitle = computed(() => {
  return props.customPageSection.header_data.subtitle
})

const hasTitle = computed(() => {
  return Object.keys(TITLE_MODE_MAP).includes(headerMode.value)
})

const containerClass = computed(() => {
  return [TITLE_MODE_MAP[headerMode.value]].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'

.vc-shared-custom-page-section-common-template-section
  .odd-container
    +utils.from(size.$macbook-13inch)
      max-width: size.$macbook-13inch-width
  > .page-section-header
    padding-top: 2rem
    padding-bottom: 1rem
    display: flex
    .section-title-wrapper
      display: flex
      flex-direction: column
      > .section-title
        padding: 0

  > .page-section-body
    padding-top: 0

  &.-is-align-center-1
    > .page-section-header
      justify-content: center
      > .section-title-wrapper
        > .section-title
          font-size: font.$size-5
          font-weight: font.$medium
          margin-bottom: .5rem
          display: flex
          flex-direction: row-reverse
          justify-content: center
          align-items: center
          > .title-1
            &:before
              content: '|'
              font-weight: font.$medium
              margin: 0 .25rem
        > .section-subtitle
          font-size: font.$normal
          margin-bottom: 10px

  &.-is-align-left-1
    > .page-section-header
      justify-content: flex-start
      > .section-title-wrapper
        > .section-title
          font-size: font.$size-3
          font-weight: font.$bold
          margin-bottom: .5rem
          display: flex
          gap: .5rem
        > .section-subtitle
          font-size: font.$normal
          font-weight: font.$bold
          margin-top: 0

  &.-is-align-left-2
    > .page-section-header
      justify-content: flex-start
      > .section-title-wrapper
        > .section-title
          font-size: font.$size-4
          font-weight: font.$bold
          margin-bottom: .5rem
          display: flex
          gap: .5rem
        > .section-subtitle
          color: color.$ci
          font-size: font.$size-3
          font-weight: font.$bold
          margin-top: 0
</style>
