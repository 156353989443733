<template lang="pug">
.vc-add-on-selector
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )
  .top-section
    span {{ actionLocaleText('choose_add_on_product') }}
    .button.is-odd(@click="$parent.close()")
      icon(icon="mdi:times")

  .body-section
    radio-card(
      v-for="result in results"
      :key="result.price.id"
      :info="result"
      @update:addOnSelected="addInfoToList($event)"
      @update:addOnRemoved="removeInfoFromList($event)"
    )

  .bottom-section
    .button.is-white(@click="$parent.close()")
      icon(icon="fa-chevron-left")
      span {{ actionLocaleText('back_to_store') }}
    .button.is-odd(@click="checkout()")
      span {{ actionLocaleText('checkout') }}
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue'
import { useI18n, useStore, useInstance } from 'skid-composables'
import { Icon } from '@iconify/vue2'
import RadioCard from './radio-card.vue'
import useAddOnPresentable from '@/shared/composables/use_add_on_presentable.js'

const props = defineProps({
  attachedProductIds: {
    type: Array,
    required: true
  },

  isFetchingInitialData: {
    type: Boolean,
    required: false,
    default: () => true
  }
})
const store = useStore()
const isLoading = computed(() => store.getters['users/isLoading'])
const vueInstance = useInstance()
const { messageLocaleText } = useI18n()
const { fetchAddOnGroups, cartService, results } = useAddOnPresentable(store)

const selectedAddOnInfo = ref([])

const checkout = () =>
  Promise.all(
    selectedAddOnInfo.value.map((info) => {
      const requestBody = {
        data: {
          type: 'order_items',
          attributes: {
            add_on_item_price_id: info.value.id,
            variant_id: info.value.variant_id,
            quantity: info.value.quantity
          }
        }
      }

      return cartService.addItemToCart(requestBody)
    })
  )
    .then(() => {
      if (selectedAddOnInfo.value.length != 0) {
        store.dispatch('addFlashMessage', [
          'success',
          messageLocaleText('product_added_to_cart_successfully')
        ])
      }

      Turbolinks.visit('/cart')
    })
    .finally(() => {
      vueInstance.$parent.close()
    })

onMounted(() => {
  if (props.isFetchingInitialData) fetchAddOnGroups(props.attachedProductIds)
})

const addInfoToList = (info) => {
  selectedAddOnInfo.value.push(info)
}
const removeInfoFromList = (info) => {
  const index = selectedAddOnInfo.value.findIndex(
    (i) => i.value.id === info.value.id
  )
  if (index > -1) selectedAddOnInfo.value.splice(index, 1)
}
</script>
