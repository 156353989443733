<template lang="pug">
k-card.vc-shared-promoter-share-kol-card(style-type="kol-card-v1")
  template(#image-wrapper)
    bg-image(:promoter-share="promoterShare")

  template(#overlay-image-wrapper)
    avatar-image(
      :promoter-share="promoterShare"
      dimension="85px"
      :options="{ levelDimension: '20px' }"
    )

    span {{ promoterMember.name }}

  template(#image-info-wrapper)
    a(
      :href="showLink"
      style="padding: 0.5rem"
      target="_blank"
    ) 查看全部

  template(#info-wrapper)
    .item(
      v-for="(shareProductShip, index) in promoterShareProductShips"
      :key="shareProductShip.id"
    )
      .item-image-wrapper
        k-image.image(
          :src="shareProductShip.product.cover.url"
          style-type="product-image"
          dimension="42px"
          :options="{ ratio: '1by1', borderRadius: '25%' }"
        )
      .item-info
        .title {{ shareProductShip.product.name }}
        .note
          | TWD
          | {{ toMoney(shareProductShip.master_event_variant_ship.price).format() }}
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import kCard from '@sharedComponents/common/k-card.vue'
import kImage from '@sharedComponents/common/k-image.vue'
import bgImage from './bg-image.vue'
import avatarImage from './avatar-image.vue'
import DefaultKolnetIcon from '@kolcenter/images/default-kolnet.png'

const store = useStore()
const props = defineProps({
  promoterShare: { type: Object, required: true }
})
const data = reactive({
  shareProductShipIds: []
})

const promoterMember = computed(() => {
  const promoter = store.getters['promoters/find'](
    props.promoterShare.promoter_id
  )

  return store.getters['promoterMembers/find'](promoter.promoter_member_id)
})

async function fetchPromoterShareProductShips() {
  const response = await store.dispatch(
    'promoterShares/fetchBriefShareProductShips',
    {
      model: props.promoterShare,
      options: {
        pageSize: 3,
        pageNumber: 1
      }
    }
  )

  data.shareProductShipIds = response.data.data.map((row) => row.id)
}

const promoterShareProductShips = computed(() => {
  return data.shareProductShipIds.map((id) => {
    return store.getters['promoterShareProductShips/find'](id)
  })
})

function masterEventVariantShipFor(shareProductShip) {
  return store.getters['promoterEventVariantShips/find'](
    shareProductShip.value.event_variant_ship_id
  )
}

const showLink = computed(() => {
  return props.promoterShare.storeFrontUrl()
})

onMounted(() => {
  fetchPromoterShareProductShips()
})
</script>
