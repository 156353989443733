<template lang="pug">
.vc-add-on-radio-card
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )
  .main-wrapper(:class="{ disabled: disabled }")
    .radio-wrapper(
      v-if="!showBuyButton"
      @click="toggleSelect"
    )
      icon.radio-icon(
        icon="mdi:check-circle"
        :class="{ selected: isSelect }"
      )
    .cover-wrapper(@click="toggleSelect")
      .indicator(v-if="discountRate") {{ discountRate }}
      img.img(:src="cover")

    .info-wrapper
      .name {{ name }}
      .price-info
        .price(:data-currency="currentCurrency") {{ price.amount }}
        .price.original(
          v-if="discountRate"
          :data-currency="currentCurrency"
        ) {{ originalPrice.amount }}

      .stock-hint(v-if="stock < 1")
        | {{ copyLocaleText('stock_status.no_stock') }}
      .action-section(v-else)
        b-numberinput(
          v-model="data.quantity"
          :min="1"
          :max="availableStock"
          :disabled="disabled"
          controls-position="compact"
          size="is-small"
          type="is-default"
        )
        b-button(
          v-if="showBuyButton"
          :disabled="buttonDisabled"
          @click="addToCard"
        )
          icon(icon="mdi:cart")
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue2'
import {
  useStore,
  useMoney,
  useI18n,
  useToast,
  useModal,
  useInstance
} from 'skid-composables'
import { computed, ref } from 'vue'
import useAddOnPresentable from '@/shared/composables/use_add_on_presentable.js'
import VariantsSelector from '@applicationComponents/product/variants-selector.vue'

const props = defineProps({
  info: {
    type: Object,
    required: true
  },
  showBuyButton: {
    type: Boolean,
    required: false
  },
  mainProduct: {
    type: Object,
    required: false
  }
})

const { toMoney } = useMoney()
const store = useStore()
const vueInstance = useInstance()
const isLoading = computed(() => store.getters['users/isLoading'])

const isSelect = ref(false)
const data = ref({
  id: props.info.price.id,
  variant_id: props.info.variant.id,
  quantity: 1
})
const cover = computed(() => props.info.variant.cover.thumb.url)
const name = computed(() => {
  const spec = props.info.variant.name

  return props.info.variant.product_name + (spec ? ` - ${spec}` : '')
})
const { messageLocaleText } = useI18n()
const {
  isMainProductPurchased,
  cartService,
  allItemQuantities,
  addOnItemQuantities
} = useAddOnPresentable(store)

const currentPurchaseQuantity = computed(() => {
  return allItemQuantities.value[data.value.variant_id] || 0
})
const currentAddOnQuantity = computed(() => {
  return addOnItemQuantities.value[data.value.variant_id] || 0
})

const stock = computed(() => props.info.variant.stock)
const availableStock = computed(() => {
  const currentResult = stock.value - currentPurchaseQuantity.value
  const limitQuantity = props.info.price.limit_quantity
  const isLimited = !!limitQuantity

  const addOnRemainingPurchasableQuantity =
    limitQuantity - currentAddOnQuantity.value

  return isLimited
    ? Math.min(addOnRemainingPurchasableQuantity, currentResult)
    : currentResult
})
const disabled = computed(() => availableStock.value < 1)
const buttonDisabled = computed(
  () => disabled.value || data.value.quantity > availableStock.value
)
const price = computed(() => toMoney(props.info.price.price))
const originalPrice = computed(() => toMoney(props.info.price.original_price))
const discountRate = computed(() => props.info.price.displayDiscountRate)

const chooseMainProduct = async () => {
  return new Promise((resolve) => {
    useModal({
      parent: vueInstance,
      component: VariantsSelector,
      width: 460,
      props: {
        isAddToCartOnly: true,
        product: props.mainProduct
      },
      events: {
        'main-product-added': () => resolve(true)
      },
      onCancel: () => resolve(false)
    })
  })
}

const addToCard = async () => {
  if (disabled.value) return

  if (props.showBuyButton && !isMainProductPurchased.value) {
    useToast({
      message: messageLocaleText('add_on.please_choose_main_product_first'),
      type: 'is-info'
    })

    const productAdded = await chooseMainProduct()

    if (!productAdded) return
  }

  const requestBody = {
    data: {
      type: 'order_items',
      attributes: {
        add_on_item_price_id: data.value.id,
        variant_id: data.value.variant_id,
        quantity: data.value.quantity
      }
    }
  }

  return cartService
    .addItemToCart(requestBody)
    .then(() => {
      data.value.quantity = 1
      return store.dispatch('addFlashMessage', [
        'success',
        messageLocaleText('product_added_to_cart_successfully')
      ])
    })
    .catch((errors) => {
      const message =
        errors.response.data?.validation_errors?.add_on_item_price_id

      if (message) {
        useToast({
          message: message,
          type: 'is-info'
        })
      }
    })
}

const emits = defineEmits(['update:addOnSelected', 'update:addOnRemoved'])
const toggleSelect = () => {
  if (disabled.value || props.showBuyButton) return
  isSelect.value = !isSelect.value

  emits(isSelect.value ? 'update:addOnSelected' : 'update:addOnRemoved', data)
}
</script>
