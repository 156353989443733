<template lang="pug">
template-section.vc-custom-page-section-match-product-section(
  :custom-page-section="customPageSection"
)
  template(#body)
    .content-wrapper
      .match-product-index-container-wrapper
        match-product-index-container(:promoter-share-id="promoterShareId")
      .info-wrapper
        .caption1
          span KOKO Select
        .caption2
          span 選品配對
        .caption3
          span 好選。好快。好多
        .caption4
          span 輕鬆滑動找到商機！
        .caption5
          span KOKO Select 為您帶來創新且引人入勝的「卡片媒合模式」，讓您僅需輕輕滑動，就能探索眾多精彩商品和機會。這一獨特功能讓您輕鬆瀏覽各類熱門商品、合作品牌和商業機遇，只要向左或向右滑動卡片，即可迅速篩選出最適合您的選擇。
</template>

<script setup lang="ts">
import { computed } from 'vue'
import TemplateSection from './template-section.vue'
import matchProductIndexContainer from '@kolcenterComponents/kolcenter_match/index-container.vue'

interface CustomPageSection {
  data: {
    title: string
    subtitle: string
  }
}

// props
const props = defineProps<{
  customPageSection: CustomPageSection
}>()

//- TO-DO set current user for promoter_member
const promoterShareId = computed(() => {
  return null
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'

.vc-custom-page-section-match-product-section
  position: relative
  margin-bottom: -3rem
  &:before
    content: ""
    position: absolute
    top: 0px
    left: 0px
    bottom: 0px
    right: 0px
    z-index: 2
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(12, 10, 8, .2) 100%)

  .content-wrapper
    display: block
    > .info-wrapper
      display: none


    +utils.from(size.$ipad)
      display: grid
      grid-template-columns: 40% 40%
      // .match-product-index-container-wrapper
      > .info-wrapper
        display: flex
        flex-direction: column
        justify-content: center
        > .caption1
          font-size: 36px
          font-weight: font.$bold
        > .caption2
          font-size: 48px
          font-weight: font.$bold
        > .caption3
          font-size: 48px
          font-weight: font.$bold
          color: color.$ci
        > .caption4
          font-size: 24px
          font-weight: font.$bold
          color: color.$chicago
          margin-bottom: 2rem
        > .caption5
          font-size: font.$size-5
          color: color.$black
</style>
