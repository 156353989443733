<script setup lang="ts">
import { computed } from 'vue'

type Video = {
  id: string
  title: string
  description: string
  thumbnail_url: string
  stream_url: string
}

const props = withDefaults(
  defineProps<{
    video: Video
    isMuted?: boolean
    autoplay?: boolean
    width?: number
  }>(),
  {
    isMuted: true,
    autoplay: true,
    width: 520
  }
)

const videoUrl = computed(() => {
  const separator = props.video.stream_url.includes('?') ? '&' : '?'
  const options = new URLSearchParams()

  if (props.autoplay) options.append('autoplay', 'true')
  if (props.isMuted) options.append('muted', 'true')
  options.append('width', props.width.toString())

  return `${props.video.stream_url}${separator}${options.toString()}`
})
</script>

<template>
  <div class="vc-video-modal">
    <section class="video-wrapper">
      <div class="ui">
        <iframe
          id="stream-player"
          :src="videoUrl"
          loading="lazy"
          style="
            border: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          "
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"></iframe>
        <h1 class="title">{{ props.video.title }}</h1>
        <div class="option-buttons">
          <!--<div class="button is-primary purchase-btn">Go to purchase</div>-->
          <!-- anticipate the need for a ... button
          <div class="button is-danger like-btn">
            <icon icon="mdi:heart" />
          </div>
          -->
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.vc-video-modal {
  --video-aspect-ratio: 1 / 1.777;
  --video-border-radius: 1rem;

  display: grid;
  place-items: center;
  padding-block: 1rem;

  .video-wrapper {
    position: relative;
    height: 80svh;
    max-height: 600px;
    aspect-ratio: var(--video-aspect-ratio);
    border-radius: var(--video-border-radius);
    box-shadow: 0 0 1rem hsl(197 100% 50% / 50%);
    background-color: hsl(0 0% 10%);
    overflow: hidden;
  }

  .ui {
    --text-color: hsl(0 0% 100%);
    --overlay-color: hsl(0 0% 0% / 50%);
    --transparent-color: hsl(0 0% 0% / 0%);

    position: absolute;
    display: grid;
    inset: 0;
    padding: 0.25em;
    font-size: 14px;
    color: var(--text-color);
    background-image: linear-gradient(
      to bottom,
      var(--overlay-color) 0%,
      var(--transparent-color) 15%,
      var(--transparent-color) 85%,
      var(--overlay-color) 100%
    );

    > .title {
      position: relative;
      text-align: center;
      align-self: start;
      font-weight: bold;
      font-size: 1rem;
      color: var(--text-color);
    }

    > .option-buttons {
      position: relative;
      display: flex;
      gap: 1em;
      align-self: end;

      > .button {
        width: 100%;
        padding-inline: 1em;
      }

      > .button.like-btn {
        width: auto;
      }
    }
  }
}
</style>
