<template lang="pug">
.k-shared-partner-account-fields(style="width: 100%")
  b-field(
    :type="errors.errorClassAt('email')"
    :message="errors.get('email')"
    custom-class="required"
    horizontal
  )
    template(#label)
      p {{ attributeLocaleText('partner', 'email') }}
    b-input(
      type="text"
      placeholder="輸入 Email"
      v-model="form.email"
      data-behavior="partner-email"
      name="partner[email]"
      @input="errors.clear('email')"
    )

  b-field(
    :label="attributeLocaleText('partner', 'reveal_name')"
    :type="errors.errorClassAt('name')"
    :message="errors.get('name')"
    custom-class="required"
    horizontal
  )
    b-input(
      type="text"
      v-model="form.name"
      data-behavior="partner-name"
      @input="errors.clear('name')"
    )

  b-field(
    :label="attributeLocaleText('partner', 'password')"
    :type="errors.errorClassAt('password')"
    :message="errors.get('password')"
    custom-class="required"
    horizontal
  )
    b-input(
      type="password"
      :placeholder="attributeLocaleText('admin', 'password')"
      autocomplete="new-password"
      v-model="form.password"
      data-behavior="partner-password"
      name="partner[password]"
      @input="errors.clear('password')"
    )

  b-field(
    :label="attributeLocaleText('partner', 'password_confirmation')"
    :type="errors.errorClassAt('password_confirmation')"
    :message="errors.get('password_confirmation')"
    custom-class="required"
    horizontal
  )
    b-input(
      type="password"
      :placeholder="messageLocaleText('help.please_re_enter_x_for_confirmation', { x: attributeLocaleText('admin', 'password') })"
      v-model="form.password_confirmation"
      data-behavior="partner-password-confirmation"
      @input="errors.clear('password_confirmation')"
    )
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    form: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      required: true
    },

    isIncludePassword: {
      type: Boolean,
      required: false
    }
  }
})
</script>
