<template lang="pug">
.vc-sales-event-k-select-events
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .list-wrapper
    card(
      v-for="event in salesEvents"
      :key="event.id"
      :sales-event="event"
    )
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import card from './card_list/card.vue'

// props
const props = defineProps<{
  salesEventIds?: Array<Object>
}>()

const store = useStore()

// computed
/**
 * @returns {computedRef<Boolean>}
 */
const isLoading = computed(() => {
  return store.getters['salesEvents/isLoading']
})
/**
 * @returns {computedRef<Boolean>}
 */
const salesEvents = computed(() => {
  return store.getters['salesEvents/all'].filter((salesEvent) => {
    return props.salesEventIds.includes(salesEvent.id)
  })
})

// mounted
onMounted(async () => {
  await fetchingSelectSalesEvents()
})

// methods
/**
 * @reutrns {void}
 */
const fetchingSelectSalesEvents = () => {
  if (props.salesEventIds.length === 0) return

  store.dispatch('salesEvents/all', {
    search: {
      id_in: props.salesEventIds
    }
  })
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'

.vc-sales-event-k-select-events
  min-height: 300px
  $gap: .5rem
  .list-wrapper
    width: 100%
    display: flex
    flex-direction: column
    +utils.from(size.$ipad-pro-105)
      flex-direction: row
  .vc-sales-event-k-card
    width: 100%
    +utils.from(size.$ipad-pro-105)
      width: 50%
      padding: $gap
    +utils.from(size.$ipad-pro)
      width: 25%
</style>
