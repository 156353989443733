import productPropsMixin from './product_props_mixin.js'
import ShoppingCartService from '../../../../shared/services/shopping_cart_service.js'
import QuantityPicker from '../product/quantity-picker.vue'
import generateGA4Events from '@services/generate_ga4_events'
import AddOnSelector from '@applicationComponents/add_on/selector.vue'
import useAddOnPresentable from '@/shared/composables/use_add_on_presentable.js'
import useWarningInfo from '@/shared/composables/use_warning_info.js'

export default {
  components: {
    QuantityPicker
  },

  mixins: [productPropsMixin],

  // props: {},

  data() {
    return {
      selectedVariantId: null,
      quantity: 1,
      cartService: null,
      isProcessing: false,
      useAddOnPresentable: null
    }
  },

  computed: {
    isSubscription() {
      return this.product.is_subscription
    },

    isVariantsLoaded() {
      return typeof this.selectedVariantId === 'string'
    },

    isPurchasable() {
      return (
        this.product.isReleased &&
        this.selectedVariant &&
        this.selectedVariant.isPurchasable()
      )
    },

    unPurchaseableText() {
      return this.copyLocaleText(
        this.product.isReleased ? 'stock_status.no_stock' : 'coming_soon'
      )
    },

    subTotal() {
      return this.toMoney(this.selectedVariant.consumer_price).multiply(
        this.quantity
      )
    },

    masterVariant() {
      return this.variants.find((variant) => variant.is_master === true)
    },

    currentStock() {
      return this.selectedVariant.stock || 0
    }
  },

  mounted() {
    // console.log('please add `this._initializeCartService()` to mounted hook.')
    this.useAddOnPresentable = useAddOnPresentable(this.$store)
  },

  methods: {
    _initializeCartService() {
      this.cartService = new ShoppingCartService(this.$vlf, this.$store)
    },

    addToCart() {
      console.log('please implement method `#addToCart` in component.')
    },

    buyNow() {
      console.log('please implement method `#buyNow` in component.')
    },

    _callAddToCartMethod(options = {}) {
      this.isProcessing = true

      if (options.triggerAddOnSelector) {
        this.useAddOnPresentable.fetchAddOnGroups([
          this.selectedVariant.product_id || this.selectedVariant.id
        ])
      }

      return this.cartService
        .addItemToCart(this.requestBody)
        .then(() => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('product_added_to_cart_successfully')
          ])
        })
        .then((_) => {
          if (
            options.triggerAddOnSelector &&
            this.useAddOnPresentable.results.length > 0
          )
            this.openAddOnSelector()

          useWarningInfo(
            'add_to_cart',
            [{ variant_id: this.selectedVariantId }],
            { vueInstance: this, store: this.$store }
          )

          return this._tracingAddToCartEvent()
        })
    },

    _tracingAddToCartEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addProduct',
            {
              id: this.selectedVariant.sku,
              name: this.selectedVariant.product_name,
              brand: this.product.brand_name,
              variant: this.selectedVariant.name,
              position: this.index + 1,
              price: this.toMoney(this.selectedVariant.consumer_price, {
                isExchange: false
              }).amount,
              quantity: this.quantity
            }
          ],
          [
            'ec:setAction',
            'add',
            {
              list: this.listLocation
            }
          ],
          [
            'send',
            {
              hitType: 'event',
              eventCategory: 'Ecommerce',
              eventAction: 'Add to Cart'
            }
          ]
        ])
        .then((_) => {
          return this.$store.dispatch('ga4Operation', [
            generateGA4Events('add_to_cart', {
              item: this.selectedVariant,
              quantity: this.quantity
            })
          ])
        })
        .then((_) => {
          return this.$store.dispatch('pixelOperation', [
            [
              'track',
              'AddToCart',
              {
                content_ids: this.selectedVariant.sku,
                content_name: this.selectedVariant.product_name,
                content_type: 'Product',
                currency: 'TWD',
                value: this.toMoney(this.selectedVariant.consumer_price, {
                  isExchange: false
                }).amount,
                quantity: this.quantity
              }
            ]
          ])
        })
    },

    async openAddOnSelector() {
      this.$buefy.modal.open({
        parent: this,
        width: 460,
        component: AddOnSelector,
        props: {
          attachedProductIds: [
            this.selectedVariant.product_id || this.selectedVariant.id
          ],
          isFetchingInitialData: false
        }
      })
    }
  }
}
